import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      pattern: file(relativePath: { eq: "pattern.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <footer className="footer">
      <div className="container">
        <div className="site">
          <Link to={`/about/`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="82" height="37" viewBox="0 0 82 37" preserveAspectRatio="xMinYMid">
          <text x="12" y="50%" dy="0.4em" dominant-baseline="alphabetic" letter-spacing="11" font-weight="bold" font-size="10" font-family="Meiryo UI" fill="#ffffff"  stroke-width="23" stroke="#000000" stroke-linejoin="round" >MaiP</text>
          <text x="12" y="50%" dy="0.4em" dominant-baseline="alphabetic" letter-spacing="11" font-weight="bold" font-size="10" font-family="Meiryo UI" fill="#ffffff"  stroke-width="0">MaiP</text>
          </svg>
            {/* <p>面白コンテンツを記載するサイト</p> */}
          </Link>
        </div>
        <ul className="sns">
          <li>
            <a href="https://twitter.com/maip06688337/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} size="sm" />
              <span className="sr-only">Twitter</span>
            </a>
          </li>
        </ul>
      </div>
      {/* <div className="back">
        <Img
          fluid={data.pattern.childImageSharp.fluid}
          alt=""
          style={{ height: "100%" }}
        />
      </div> */}
    </footer>
  )
}
