import React from "react"
import { Link } from "gatsby"
import { StaticImage  } from 'gatsby-plugin-image'

export default () => (
  <header className="header">
    <div className="container">
      <div className="site">
      {/* <StaticImage src="../logo.png"></StaticImage> */}
        <Link to={`/about/`}>
          {/* <StaticImage src="/logo.png"></StaticImage> */}
          {/* <img src="../logo.png" width="70" height="70"></img> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="82" height="37" viewBox="0 0 82 37" preserveAspectRatio="xMinYMid">
<text x="12" y="50%" dy="0.4em" dominant-baseline="alphabetic" letter-spacing="11" font-weight="bold" font-size="10" font-family="Meiryo UI" fill="#ffffff"  stroke-width="23" stroke="#000000" stroke-linejoin="round" >MaiP</text>
<text x="12" y="50%" dy="0.4em" dominant-baseline="alphabetic" letter-spacing="11" font-weight="bold" font-size="10" font-family="Meiryo UI" fill="#ffffff"  stroke-width="0">MaiP</text>
</svg>
       </Link>
      </div>
      <nav className="nav">
        <ul>
          <li>
            <Link to={`/tiktok-follower-5/`} className="headerLink">TIKTOK</Link>
          </li>
          <li>
            <Link to={`/news/`} className="headerLink">NEWS</Link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
)
